import { AnyAction } from 'redux';
import * as ActionTypes from '@stores/shareable-lists/action.types';
import produce from 'immer';
import {
    IShareableList,
    IShareableListCreateInput,
    ShareableListAddItemsInput,
    ShareableListItem,
    ShareableListRemoveItemsInput,
    IShareableListModuleState
} from '@interfaces/shareable-lists';

export const defaultState: IShareableListModuleState = {
    lists: [],
    detailedPage: {
        editMode: false,
        selectedItems: {
            partners: []
        },
        checkedItems: {
            partners: []
        }
    },
    listsSearchState: '',
    flags: {
        fetchingListsByOwnerId: {
            inProgress: false
        },
        creatingList: {
            inProgress: false
        }
    }
};

export const reducer = produce(
    (state: IShareableListModuleState = defaultState, action: AnyAction) => {
        const { type, payload } = action;
        switch (type) {
            case ActionTypes.SHAREABLE_LISTS_MODULE_FETCH_BY_OWNER_ID_SUCCESS: {
                const { shareableLists }: { shareableLists: IShareableList[] } = payload;

                // Following code will append to lists
                // state.lists = state.lists && state.lists.length > 0
                //   ? [...state.lists, ...shareableLists]
                //   : shareableLists;

                state.lists = shareableLists;
                state.flags.fetchingListsByOwnerId.inProgress = false;
                break;
            }

            case ActionTypes.SHAREABLE_LISTS_MODULE_CREATE_LIST_ASYNC: {
                const { input }: { input: IShareableListCreateInput } = payload;
                state.flags.creatingList.input = input;
                state.flags.creatingList.inProgress = true;

                if (!state.lists || state.lists.length == 0) {
                    state.lists = [];
                }
                state.lists = [...state.lists, input];
                break;
            }

            case ActionTypes.SHAREABLE_LISTS_MODULE_CREATE_LIST_SUCCESS: {
                const { shareableList }: { shareableList: IShareableList } = payload;
                state.flags.creatingList.inProgress = false;
                let listsClone = state.lists;
                for (let i = 0; i < listsClone.length; i++) {
                    if (listsClone[i].name === shareableList.name) {
                        listsClone[i]['_id'] = shareableList._id;
                    }
                }
                state.lists = listsClone;
                break;
            }

            case ActionTypes.SHAREABLE_LISTS_MODULE_CREATE_LIST_FAILED: {
                const { error }: { error: Error } = payload;
                state.error = error;
                break;
            }

            case ActionTypes.SHAREABLE_LISTS_MODULE_DELETE_BY_ID_ASYNC: {
                const { listId }: { listId: string } = payload;
                state.lists = state.lists.filter((list) => {
                    if ('_id' in list && list._id) {
                        if (list._id === listId) {
                            return false;
                        }
                    }
                    return true;
                });
                break;
            }

            case ActionTypes.SHAREABLE_LISTS_MODULE_DELETE_BY_ID_FAILED: {
                const { listId, error }: { listId: string; error: Error } = payload;
                break;
            }

            /// Detailed Page

            case ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SET_LIST: {
                const { shareableList }: { shareableList: IShareableList } = payload;
                state.detailedPage.list = shareableList;
                break;
            }

            // UI

            case ActionTypes.SHAREABLE_LISTS_MODULE_PAGE_SET_SEARCH_STATE: {
                const { searchState } = payload;
                state.listsSearchState = searchState;
                break;
            }

            case ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SELECT_APPEND_ITEM: {
                const {
                    itemType,
                    itemId
                }: {
                    itemType: ShareableListItem;
                    itemId: string;
                } = payload;

                if (state.detailedPage.list) {
                    if (itemType == 'partner') {
                        // Find the partner in the list items
                        let partnerIndex;
                        let selectedPartner = state.detailedPage.list.items.partners.find(
                            (partner, index) => {
                                if (partner._id === itemId) {
                                    partnerIndex = index;
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        );

                        if (selectedPartner) {
                            // // Remove the selected item from list in partners
                            // state.detailedPage.list.items.partners.splice(partnerIndex, 1);

                            // Add the found partner to selected items
                            state.detailedPage.selectedItems.partners = [
                                ...state.detailedPage.selectedItems.partners,
                                selectedPartner
                            ];
                        }
                    }
                }

                break;
            }

            case ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SELECT_REMOVE_ITEM: {
                const {
                    itemType,
                    itemId
                }: {
                    itemType: ShareableListItem;
                    itemId: string;
                } = payload;

                if (state.detailedPage.list) {
                    if (itemType == 'partner') {
                        // Find the partner in the list items
                        let partnerIndex;
                        let selectedPartner = state.detailedPage.selectedItems.partners.find(
                            (partner, index) => {
                                if (partner._id === itemId) {
                                    partnerIndex = index;
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        );

                        if (selectedPartner) {
                            // Remove the selected item from selected items
                            state.detailedPage.selectedItems.partners.splice(partnerIndex, 1);

                            // Add the found partner to items in main list
                            state.detailedPage.list.items.partners = [
                                ...state.detailedPage.list.items.partners,
                                selectedPartner
                            ];
                        }
                    }
                }

                break;
            }

            case ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_CHECK_APPEND_ITEM: {
                const {
                    itemType,
                    itemId
                }: {
                    itemType: ShareableListItem;
                    itemId: string;
                } = payload;

                if (state.detailedPage.list) {
                    if (itemType == 'partner') {
                        // Find the partner in the list items
                        let partnerIndex;
                        let selectedPartner = state.detailedPage.list.items.partners.find(
                            (partner, index) => {
                                if (partner._id === itemId) {
                                    partnerIndex = index;
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        );

                        if (selectedPartner) {
                            // // Remove the selected item from list in partners
                            // state.detailedPage.list.items.partners.splice(partnerIndex, 1);

                            // Add the found partner to selected items
                            state.detailedPage.checkedItems.partners = [
                                ...state.detailedPage.checkedItems.partners,
                                selectedPartner
                            ];
                        }
                    }
                }

                break;
            }

            case ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_CHECK_REMOVE_ITEM: {
                const {
                    itemType,
                    itemId
                }: {
                    itemType: ShareableListItem;
                    itemId: string;
                } = payload;

                if (state.detailedPage.list) {
                    if (itemType == 'partner') {
                        // Find the partner in the list items
                        let partnerIndex;
                        let selectedPartner = state.detailedPage.checkedItems.partners.find(
                            (partner, index) => {
                                if (partner._id === itemId) {
                                    partnerIndex = index;
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        );

                        if (selectedPartner) {
                            // Remove the selected item from list in partners
                            state.detailedPage.checkedItems.partners.splice(partnerIndex, 1);

                            // Add the found partner to selected items
                            state.detailedPage.list.items.partners = [
                                ...state.detailedPage.list.items.partners,
                                selectedPartner
                            ];
                        }
                    }
                }
                break;
            }

            case ActionTypes.SHAREABLE_LISTS_MODULE_ADD_ITEMS_TO_LIST_ASYNC: {
                const {
                    items,
                    listId,
                    listName
                }: {
                    items: ShareableListAddItemsInput;
                    listId: string;
                    listName: string;
                } = payload;

                let listIndex: number;
                const list: IShareableList | IShareableListCreateInput = state.lists.find(
                    (list, index) => {
                        if (list.name === listName) {
                            listIndex = index;
                            return true;
                        } else {
                            return false;
                        }
                    }
                );

                if (!list || !listIndex) {
                    break;
                }

                if (!list.items || !list.items.partners) {
                    list.items = {
                        partners: []
                    };
                }

                const itemTypesInItems = Object.keys(items);
                for (const itemType in itemTypesInItems) {
                    if (itemType == 'partners') {
                        state.lists[listIndex].items.partners = [
                            ...state.lists[listIndex].items.partners,
                            ...items.partners.partners
                        ];
                    }
                }
                break;
            }

            case ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_REMOVE_ITEMS_FROM_LIST_ASYNC: {
                const {
                    items,
                    listId,
                    listName
                }: {
                    items: ShareableListRemoveItemsInput;
                    listId: string;
                    listName: string;
                } = payload;

                const partnerItemIds = items.partners.ids;

                const filter = (partner) => {
                    if (partner._id) {
                        if (!partnerItemIds.includes(partner._id)) {
                            return true;
                        }
                    }
                    return false;
                };

                state.detailedPage.list.items.partners = state.detailedPage.list.items.partners.filter(
                    filter
                );
                state.detailedPage.selectedItems.partners = state.detailedPage.selectedItems.partners.filter(
                    filter
                );
                state.detailedPage.checkedItems.partners = state.detailedPage.checkedItems.partners.filter(
                    filter
                );

                break;
            }

            case ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SET_EDIT_MODE: {
                const { mode }: { mode: boolean } = payload;
                state.detailedPage.editMode = mode;
                break;
            }

            default: {
                return state;
            }
        }
    }
);

export default reducer;
