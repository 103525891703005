import { IModulesService } from '@interfaces/modules-service';

export default class IsModuleActiveUseCase {
    private service: IModulesService;
    constructor(service: IModulesService) {
        this.service = service;
    }
    public execute(moduleName: string) {
        return this.service.isModuleActive(moduleName);
    }
}
