import '../styles/_app.scss';
import '../styles/index.css';
import React from 'react';
import Head from 'next/head';
import Amplify from '@aws-amplify/core';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import Router from 'next/router';
import { ContainerProvider } from '../react-binding';
import { container } from '../container';
import { wrapper } from '../redux/store';
import { ThemeProvider } from 'emotion-theming';
import { theme } from '../utils/theming/theming';
import { FocusStyleManager } from '@blueprintjs/core';
import EN from '../locales/en.json';
import FI from '../locales/fi.json';

/**
 * "Subscribes" nprogress library on all router events related to page reload or load
 */
Router.events.on('routeChangeStart', (url) => {
    console.log(`Loading: ${url}`);
    NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

/**
 * The line of code below turns off the default outline on inputs, buttons and other interactive elements
 * With this line of code outline will not be shown, it is used to make the visuals more appealing N
 */
FocusStyleManager.onlyShowFocusOnTabs();

Amplify.configure({
    ssr: true,
    Auth: {
        region: 'eu-central-1', //! Configuration
        userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
        userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID
    },

    aws_appsync_graphqlEndpoint: process.env.NEXT_PUBLIC_APPSYNC_ENDPOINT,
    aws_appsync_region: process.env.NEXT_PUBLIC_APPSYNC_AWS_REGION
        ? process.env.NEXT_PUBLIC_APPSYNC_AWS_REGION.trim()
        : 'eu-central-1',
    aws_appsync_authenticationType: process.env.NEXT_PUBLIC_APPSYNC_AUTH_TYPE
        ? process.env.NEXT_PUBLIC_APPSYNC_AUTH_TYPE.trim()
        : 'API_KEY',
    aws_appsync_apiKey: process.env.NEXT_PUBLIC_APPSYNC_API_KEY
});

function App({ Component, pageProps }) {
    Amplify.I18n.putVocabularies({
        en: EN,
        fi: FI
    });

    const { locale } = useRouter();
    Amplify.I18n.setLanguage(locale);

    return (
        <ThemeProvider theme={theme}>
            <Head>
                {/* Import CSS for nprogress */}
                <link rel="stylesheet" type="text/css" href="/nprogress.css" />
            </Head>
            <ContainerProvider container={container}>
                <Component {...pageProps} />
            </ContainerProvider>
        </ThemeProvider>
    );
}

export default wrapper.withRedux(App);
