import { IModuleConfig, IModulesService } from '@interfaces/modules-service';

export default class ModulesService implements IModulesService {
    private readonly config: IModuleConfig;
    constructor(config: IModuleConfig) {
        this.config = config;
    }

    public isModuleActive(moduleName: string): boolean {
        return this.config.active.includes(moduleName);
    }
}
