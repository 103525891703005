import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { reducer as LandingPageReducer } from '../modules/landing-pages/components';
import { reducer as HomePageReducer } from '../modules/products-display';
import { reducer as ShareableListsReducer } from '@stores/shareable-lists/reducer';
import { reducer as CoreReducer } from '../modules/core';
import { reducer as AuthReducer } from '../modules/auth/index';

const combinedReducer = combineReducers({
    landingPageState: LandingPageReducer,
    homePageState: HomePageReducer,
    shareableListsPageState: ShareableListsReducer,
    coreState: CoreReducer,
    authState: AuthReducer
});

const reducer = (state, action) => {
    if (action.type === HYDRATE) {
        return {
            ...state, // use previous state
            ...action.payload // apply delta from hydration
        };
    } else {
        return combinedReducer(state, action);
    }
};

export default reducer;
