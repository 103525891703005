import { Container } from 'inversify';
import { IModulesService } from '@interfaces/modules-service';
import ModulesService from '@services/modules-service';
import appConfig from './app.config.json';
import IsModuleActiveUseCase from '@use-cases/modules/IsModuleActiveUseCase';

const container = new Container();

const modulesService: IModulesService = new ModulesService(appConfig.modules);
container.bind<IModulesService>(ModulesService).toConstantValue(modulesService);
container.bind(IsModuleActiveUseCase).toConstantValue(new IsModuleActiveUseCase(modulesService));

export { container };
