import produce from 'immer';
import { State } from 'react-redux';
import { AnyAction } from 'redux';
import * as ActionTypes from './action.types';
import * as Types from './types';

const initialState: Types.ICoreState = {
    user: null
};

export const reducer = produce((state: Types.ICoreState = initialState, action: AnyAction) => {
    const { type, payload } = action;

    switch (type) {
        case ActionTypes.CORE_AUTH_STATE_UPDATE: {
            state.authState = payload.authState;
            break;
        }

        case ActionTypes.CORE_USER_ADD_OR_UPDATE: {
            state.user = payload.user;
            break;
        }

        default: {
            return state;
        }
    }
});
